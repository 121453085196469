
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

$bg-gray: #f5f5f5;
$gray: #999;
.bus__input {
  font-size: 16px;
  background-color: $bg-gray;
  &::placeholder {
    font-size: 16px;
    color: $gray;
  }
}
.bus__inner {
  flex-basis: calc(100% - 40px);
}
.dt__icon {
  display: flex;
  flex-basis: 30px;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  border: 1px solid $color-primary-1-day;
  color: $color-primary-1-day;
  border-radius: 50%;
  background: rgba(16, 24, 40, 0.05);
  ::v-deep .esmp-svg-icon {
    height: 20px;
    width: 20px;
  }
}
.destination-component {
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &.destination-component--open {
    z-index: 6;
  }

  &:hover,
  input:hover {
    cursor: pointer;
  }
}
