$bg-gray: #f5f5f5;
$gray: #999;
$gray-light: #ddd;
$black: #333;

.business-trip__title {
  margin-bottom: 12px;
  font-size: 24px;
  color: #101828;
  letter-spacing: 0;
}
.business-trip__expenses-block {
  background-color: $color-white;
  padding: 20px 16px;
  border-radius: $base-border-radius;
  &:not(:first-child) {
    margin-top: 20px;
  }
}
.business-trip__expenses-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: -36px;
  &:not(:last-child) {
    margin-bottom: 0;
  }
}
.business-trip__expenses-col {
  margin-bottom: 36px;
  &:not(:last-child) {
    margin-right: 20px;
  }
}
.business-trip__expenses-col--big-offset {
  &:not(:last-child) {
    margin-right: 40px;
  }
}
.business-trip__expenses-col--small {
  width: 130px;
}
.business-trip__expenses-fieldset-inner,
.business-trip__expenses-fieldset {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.business-trip__expenses-fieldset {
  .created-app__row {
    width: 100%;
    min-width: 130px;
    margin-bottom: 0;
  }
  .created__row {
    margin-bottom: 0;
  }
  .created__label {
    margin: 0;
  }
}
.business-trip__expenses-field {
  width: 200px;
  &:not(:last-child) {
    margin-right: 10px;
  }
  ::v-deep .white-field {
    border-radius: 0;
  }
}
.business-trip__expenses-field--big {
  width: 300px;
}
.business-trip__expenses-field:first-child .clndr__input,
.business-trip__expenses-field:first-child .white-field {
  border-radius: 4px 0 0 4px;
}
.business-trip__expenses-field:last-child .clndr__input,
.business-trip__expenses-field:last-child .white-field {
  border-radius: 0 4px 4px 0;
}
.business-trip__expenses-field:first-child:last-child .clndr__input,
.business-trip__expenses-field:first-child:last-child .white-field {
  border-radius: 4px;
}
.business-trip__daily-allowance {
  font-size: 20px;
  color: #101828;
  letter-spacing: 0;
  span {
    color: $color-primary-1-day;
  }
}
.business-trip__expenses-fieldset-title {
  position: absolute;
  bottom: calc(100% + 8px);
  left: 0;
  font-size: 16px;
  color: #101828;
  letter-spacing: 0;
}
.transit-block,
.accommodation-block {
  position: relative;
  width: 100%;
  margin-bottom: 36px;
  & + .transit-block {
    margin-top: 60px;
  }
}



